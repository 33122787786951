import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
import '../styles/webinar-page.css';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarNavigating = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc3228e2aace524737a65d?date=jit_15";
  const title = "Global Patent Playbook 2025: Navigating International IP (It's Extremely Dangerous!)";
  const canonical = "/webinar-navigating-international-patent-laws";
  const introAuthor = "Bao Tran is an international patent attorney with a focus on cross-border IP laws."
  const lessons = [
    "Key differences in patent laws across major markets.",
    "Strategies for managing international patent portfolios.",
    "Challenges and solutions in global patent protection.",
    "Insights on patent cooperation treaties and agreements."
  ]
  const btnTxt = "Watch Webinar Now";
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarNavigating;